import MatchesIcon from "@mui/icons-material/Favorite";
import MatchesList from "./MatchesList";
import MatchesEdit from "./MatchesEdit";

const resource = {
  list: MatchesList,
  edit: MatchesEdit,
  icon: MatchesIcon,
};

export default resource;
