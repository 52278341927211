import {
  DateInput,
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import FullNameField from "./FullNameField";
import { Link, useLocation } from "react-router-dom";

const ClientEdit = () => {
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Edit title={<ClientTitle />}>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.clients.fieldGroups.identity")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="first_name" isRequired fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="last_name" isRequired fullWidth />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateInput
                    source="date_of_birth"
                    fullWidth
                    helperText={false}
                  />
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                {translate("resources.clients.fieldGroups.address")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="city" fullWidth helperText={false} />
                </Box>
                <Box flex={2}>
                  <TextInput source="country" fullWidth helperText={false} />
                </Box>
              </Box>
              <Box mt="1em" />
              <Box mt={2}>
                <Link to={`${location.pathname}/show`}>Assign Matches</Link>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const ClientTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />;

export default ClientEdit;
