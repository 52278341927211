import ClientIcon from "@mui/icons-material/People";

import ClientShow from "./ClientShow";
import ClientList from "./ClientList";
import ClientEdit from "./ClientEdit";

const resource = {
  list: ClientList,
  edit: ClientEdit,
  show: ClientShow,
  icon: ClientIcon,
  recordRepresentation: (record: any) =>
    `${record.first_name} ${record.last_name}`,
};

export default resource;
