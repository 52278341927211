import moment from "moment";
import { supabaseClient } from "../dataProvider/supabase";
import {
  List,
  Show,
  SimpleShowLayout,
  TextField,
  Datagrid,
  NumberField,
  NumberInput,
  SelectInput,
  TextInput,
  BulkActionProps,
  useRecordContext,
} from "react-admin";
import { useMediaQuery, Theme, Stack, Button } from "@mui/material";
import { redirect } from "react-router";
import PictureField from "./PictureField";

const PossibleMatches = () => {
  return (
    <List
      resource="possible_matches_view"
      exporter={false}
      sort={{ field: "user_id", order: "DESC" }}
      filters={matchFilters}
    >
      <Datagrid rowClick={false} bulkActionButtons={<AddMatches />}>
        <TextField source="first_name" />
        <TextField source="last_name" />
        <NumberField source="age" />
        <TextField source="gender" />
        <TextField source="occupation" />
        <TextField source="about_you" />
        <TextField source="school" />
        <TextField source="school_subject" />
        <TextField source="annual_income" />
        <TextField source="faith_or_religion" />
        <TextField source="education_level" />
        <TextField source="drinks" />
        <TextField source="smokes" />
        <TextField source="exercises" />
        <PictureField source="profile_pictures" />
        <TextField source="verification_status" />
        <TextField source="waitlist_status" />
        <TextField source="is_archived" />
      </Datagrid>
    </List>
  );
};

const ClientShow = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <Show>
      <Stack direction="row">
        <SimpleShowLayout sx={{ minWidth: "24em" }}>
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="gender" />
          <TextField source="occupation" />
          <TextField source="about_you" />
          <TextField source="school" />
          <TextField source="school_subject" />
          <TextField source="annual_income" />
          <TextField source="profile_pictures" />
        </SimpleShowLayout>
        <div style={{ flexGrow: 1 }}>
          <PossibleMatches />
        </div>
      </Stack>
    </Show>
  );
};

const AddMatches = (props: BulkActionProps) => {
  const record = useRecordContext();
  async function addMatches() {
    if (props.selectedIds) {
      const newMatches = props.selectedIds.map((selectedId) => ({
        user_1_id: record?.user_id,
        user_2_id: selectedId,
        is_active: true,
        expiry: moment().add(7, "d").utc().format(),
      }));
      const { error } = await supabaseClient.from("matches").insert(newMatches);
      if (error) {
        console.error(error);
      }

      redirect("/matches");
    }
  }
  return (
    <Button onClick={addMatches}>
      <>Add Matches</>
    </Button>
  );
};

const matchFilters = [
  <NumberInput label="Max Age" source="age@lte" key="age@lte" />,
  <NumberInput label="Min Age" source="age@gte" key="age@gte" />,
  <SelectInput
    choices={[
      { id: "male", name: "Male" },
      { id: "female", name: "Female" },
      { id: "nonbinary", name: "Nonbinary" },
    ]}
    label="Gender"
    source="gender"
    key="gender"
  />,
  <TextInput label="Occupation" source="occupation" key="occupation" />,
  <TextInput label="School" source="school" key="school" />,
  <TextInput label="Subject" source="school_subject" key="school_subject" />,
  <TextInput
    label="Max Annual Income"
    source="annual_income@lte"
    key="annual_income@lte"
  />,
  <TextInput
    label="Min Annual Income"
    source="annual_income@gte"
    key="annual_income@gte"
  />,
];

export default ClientShow;
