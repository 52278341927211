import {
  BooleanInput,
  DateInput,
  Edit,
  SimpleForm,
  useTranslate,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

const MatchesEdit = () => {
  const translate = useTranslate();

  return (
    <Edit>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {translate("resources.matches.fields.edit")}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateInput source="expiry" fullWidth helperText={false} />
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="accepted_user_1"
                    fullWidth
                    helperText={false}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="accepted_user_2"
                    fullWidth
                    helperText={false}
                  />
                </Box>
              </Box>

              <Box mt="1em" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default MatchesEdit;
