import { supabaseDataProvider } from "ra-supabase";
import { supabaseClient } from "./supabase";
import { CreateResult, withLifecycleCallbacks } from "react-admin";

const createdContentItemTags: Record<string, Array<string>> = {};
const createdContentItemBuckets: Record<string, Array<string>> = {};

export const dataProvider = withLifecycleCallbacks(
  supabaseDataProvider({
    instanceUrl: import.meta.env.VITE_SUPABASE_URL || "",
    apiKey: import.meta.env.VITE_SUPABASE_ANON_KEY || "",
    primaryKeys: new Map([
      ["matches", ["match_id"]],
      ["clients_view", ["user_id"]],
      ["matchmaker_clients_view", ["user_id"]],
      ["possible_matches_view", ["user_id"]],
    ]),
    supabaseClient,
  }),
  [
    {
      resource: "content_items",
      afterRead: async (record: any) => {
        // check for tags
        // add any tags as tag ids
        const { data: tagData, error: tagError } = await supabaseClient
          .from("content_items_tags")
          .select("content_tags_id")
          .eq("content_items_id", record.id);
        if (tagError) {
          console.error(tagError);
        }
        if (tagData) {
          record.tag_ids = tagData?.map(
            ({ content_tags_id }) => content_tags_id,
          );
        }
        // check for buckets
        // add any buckets as bucket ids
        const { data: bucketData, error: bucketError } = await supabaseClient
          .from("content_items_buckets")
          .select("content_buckets_id")
          .eq("content_items_id", record.id);
        if (bucketError) {
          console.error(bucketError);
        }
        if (bucketData) {
          record.bucket_ids = bucketData?.map(
            ({ content_buckets_id }) => content_buckets_id,
          );
        }

        if (record.url) {
          const downloadResult = await supabaseClient.storage
            .from("pairus_content")
            .createSignedUrl(record.url, 60 * 60 * 24);
          if (downloadResult.data) {
            record.file = {};
            record.file.src = downloadResult.data.signedUrl;
            record.file.title = record.title;
          }
        }
        return {
          ...record,
          url: null,
        };
      },
      afterCreate: async (result: CreateResult<any>) => {
        if (result.data) {
          const tagIds = createdContentItemTags[result.data?.id];
          const { error: tagError } = await supabaseClient
            .from("content_items_tags")
            .insert(
              tagIds.map((content_tags_id: any) => ({
                content_tags_id,
                content_items_id: result.data.id,
              })),
            );
          delete createdContentItemTags[result.data?.id];
          if (tagError) {
            console.error(tagError);
          }
          const bucketIds = createdContentItemBuckets[result.data?.id];
          const bucketItemList = bucketIds.map((content_buckets_id: any) => ({
            content_buckets_id,
            content_items_id: result.data.id,
          }));
          const { error: bucketError } = await supabaseClient
            .from("content_items_buckets")
            .insert(bucketItemList);
          delete createdContentItemBuckets[result.data?.id];
          if (bucketError) {
            console.error(bucketError);
          }
        }
        console.log("afterCreate", result);
        return result;
      },
      beforeCreate: async (params: any) => {
        const id = uuid();
        params.data.id = id;
        if (params.data?.tag_ids) {
          createdContentItemTags[id] = params.data?.tag_ids;
          params.data.tag_ids = undefined;
        }
        if (params.data?.bucket_ids) {
          createdContentItemBuckets[id] = params.data?.tag_ids;
          params.data.bucket_ids = undefined;
        }
        console.log("beforeCreate", params);
        return params;
      },
      beforeUpdate: async (params: any) => {
        if (params.data?.tag_ids) {
          // add/remove tag fks
          const { error: tagUpsertErr } = await supabaseClient
            .from("content_items_tags")
            .upsert(
              params.data.tag_ids.map((content_tags_id: any) => ({
                content_tags_id,
                content_items_id: params.data.id,
              })),
              {
                ignoreDuplicates: true,
              },
            );
          const { error: tagDeleteErr } = await supabaseClient
            .from("content_items_tags")
            .delete()
            .filter(
              "content_tags_id",
              "not.in",
              `(${params.data.tag_ids.join(",")})`,
            );

          // delete params.tag_ids
          if (tagUpsertErr || tagDeleteErr) {
            console.error(tagDeleteErr ?? tagDeleteErr);
          }
          params.data.tag_ids = undefined;
        }
        if (params.data?.bucket_ids) {
          const bucketItems = {
            content_buckets_id: params.data.bucket_ids,
            content_items_id: params.data.id,
          };
          // add/remove bucket fks
          const { error: bucketUpdateErr } = await supabaseClient
            .from("content_items_buckets")
            .update(bucketItems)
            .eq("content_items_id", params.data.id);

          // delete params.tag_ids
          if (bucketUpdateErr) {
            console.error(bucketUpdateErr);
          }
          params.data.bucket_ids = undefined;
        }
        console.log("beforeUpdate", params);
        return params;
      },
      beforeSave: async (params: any) => {
        if (params?.file?.rawFile instanceof Blob) {
          const uploadResult = await supabaseClient.storage
            .from("pairus_content")
            .upload(uuid(), params.file.rawFile);
          params.url = uploadResult?.data?.path;
        }
        return {
          ...params,
          file: undefined,
        };
      },
    },
  ],
);
// TODO cleanup/replace
// quick hack to fix missing crypto.randomUUID function
function uuid() {
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  } else {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }
}
