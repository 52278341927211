import { createClient } from "@supabase/supabase-js";

const instanceUrl = import.meta.env.VITE_SUPABASE_URL || "";
const apiKey = import.meta.env.VITE_SUPABASE_ANON_KEY || "";
export const supabaseClient = createClient(instanceUrl, apiKey);

// TODO: we need a way to sync this with postgres data model, so we don't have to maintain it in 2 places
export type ClientProfilePictures = {
  url: string;
}[];

export async function getUserContent(contentURI: string) {
  const { data, error } = await supabaseClient.storage
    .from("user_uploads")
    .createSignedUrl(contentURI, 60 * 60 * 24); // URL expires in 24 hours

  if (error) {
    throw error;
  }

  return data.signedUrl;
}
