import Carousel from "react-material-ui-carousel";
import { Backdrop, CircularProgress, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldProps, useRecordContext } from "react-admin";
import {
  ClientProfilePictures,
  getUserContent,
} from "../dataProvider/supabase";

interface Props extends FieldProps {
  sx?: SxProps;
  size?: string;
}

const PictureField = ({ size = "250", sx }: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [profilePictures, setProfilePictures] = useState<string[] | null>(null);
  const record = useRecordContext();
  if (!record) return null;

  useEffect(() => {
    async function init() {
      const pictures = record.profile_pictures as ClientProfilePictures;
      let urls = [];
      for (const pic of pictures) {
        const profilePicURL = await getUserContent(pic.url);
        urls.push(profilePicURL);
      }
      setProfilePictures(urls);
    }

    init();
  }, []);

  return (
    <>
      {profilePictures && (
        <a onClick={handleOpen} style={{ cursor: "pointer" }}>
          <Carousel
            sx={{
              width: parseInt(size, 10),
              height: parseInt(size, 10),
            }}
          >
            {profilePictures.map((item) => (
              <img
                src={`${item}`}
                title={`${record.first_name} ${record.last_name}`}
                style={{
                  width: parseInt(size, 10),
                  height: parseInt(size, 10),
                }}
              />
            ))}
          </Carousel>
        </a>
      )}
      <Backdrop
        sx={{
          margin: 0,
          height: "1000",
          width: "1000",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        {profilePictures ? (
          <Carousel
            sx={{
              width: "40vw",
              height: "40vw",
            }}
          >
            {profilePictures.map((item) => (
              <img
                src={`${item}`}
                title={`${record.first_name} ${record.last_name}`}
                style={{
                  width: "40vw",
                  height: "40vw",
                }}
                onClick={handleOpen}
              />
            ))}
          </Carousel>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
    </>
  );
};

export default PictureField;
