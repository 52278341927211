import { SVGProps } from "react";
import { useTheme } from "@mui/material/styles";

const Logo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H1024V1024H0V0Z" fill="#82432A" />
      <path
        d="M800.805 367.43C796.375 321.055 774.677 283.919 737.88 255.485C709.039 233.329 675.678 224.09 639.604 224C505.164 224 370.725 224 236.285 224H222V230.997C222 368.686 222 506.285 222.09 643.885C222.09 652.406 222.995 661.018 224.351 669.449C230.77 707.931 249.665 739.864 279.591 764.531C320.457 798.169 367.831 807.228 419.275 796.195C485.365 782.023 535.813 721.655 539.791 656.712C541.961 620.294 540.605 583.607 540.605 547.009C540.605 543.242 540.786 540.999 545.668 540.999C577.945 541.179 610.221 541.179 642.588 540.999C650.092 540.999 657.596 540.551 665.009 539.564C700.089 534.9 730.828 520.727 755.42 495.342C789.866 459.641 805.507 416.855 800.805 367.34V367.43ZM486.269 642.988C485.907 687.12 463.666 718.426 423.886 737.173C357.977 768.119 285.377 720.309 277.241 656.533C275.794 645.23 276.517 633.569 276.517 622.088C276.517 515.883 276.517 409.679 276.517 303.474C276.517 296.836 276.517 290.198 275.975 283.561C275.613 279.345 276.969 278.358 281.038 278.358C304.092 278.358 327.237 278.358 350.292 278.358V654.2C350.292 669.091 362.497 681.2 377.505 681.2C392.513 681.2 404.719 669.091 404.719 654.2V397.838H486.54V413.715C486.45 490.139 486.902 566.564 486.269 642.988ZM404.809 343.839V278.448H465.113C523.698 278.358 582.284 278.717 640.78 278.179C686.798 277.73 725.313 309.215 739.779 343.839H404.809ZM702.349 467.804C683.905 480.721 663.292 486.91 640.87 487.179C609.046 487.448 577.221 487.269 545.397 487.448C541.78 487.448 541.057 486.103 541.057 482.874C541.057 459.731 541.057 436.589 540.876 413.446V397.928H746.379C742.039 425.825 726.036 451.21 702.439 467.804H702.349Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
