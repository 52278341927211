import { FieldProps, Link, useRecordContext } from "react-admin";

import FullNameField from "./FullNameField";

const ClientLinkField = (_: FieldProps) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/matchmaker_clients_view/${record.id}`}>
      <FullNameField />
    </Link>
  );
};

export default ClientLinkField;
