import {
  CreateButton,
  DatagridConfigurable,
  ExportButton,
  List,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";
import ClientLinkField from "./ClientLinkField";

const visitorFilters = [<SearchInput source="q" alwaysOn />];

const VisitorListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

const ClientList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? visitorFilters : undefined}
      perPage={25}
      actions={<VisitorListActions />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <ClientLinkField
            source="last_name"
            label="resources.clients.fields.full_name"
          />
        </DatagridConfigurable>
      )}
    </List>
  );
};

export default ClientList;
