import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  ExportButton,
  List,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";
import ClientLinkField from "../clients/ClientLinkField";
import ClientReferenceField from "../clients/ClientReferenceField";

const matchFilters = [<SearchInput source="q" alwaysOn />];

const MatchListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

const MatchesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <List
      filters={isSmall ? matchFilters : undefined}
      perPage={25}
      actions={<MatchListActions />}
    >
      <DatagridConfigurable
        rowClick="edit"
        sx={{
          "& .column-groups": {
            md: { display: "none" },
            lg: { display: "table-cell" },
          },
        }}
      >
        <ClientReferenceField
          source="user_1_id"
          label="resources.clients.fields.full_name"
        />
        <ClientReferenceField
          source="user_2_id"
          label="resources.clients.fields.full_name"
        />
        <DateField source="created_at" />
        <DateField source="expiry" />
        <BooleanField source="accepted_user_1" />
        <BooleanField source="accepted_user_2" />
      </DatagridConfigurable>
    </List>
  );
};

export default MatchesList;
