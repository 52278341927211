import {
  Create,
  FileField,
  FileInput,
  ReferenceManyField,
  SimpleForm,
  TextInput,
  required,
  Edit,
  Datagrid,
  List,
  TextField,
  DateField,
  SelectInput,
  Show,
  SimpleShowLayout,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceField,
} from "react-admin";

export function ContentItemCreate() {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput fullWidth source="title" validate={[required()]} />
        <SelectInput
          fullWidth
          validate={[required()]}
          source="type"
          choices={[
            { id: "blog", name: "Blog" },
            { id: "podcast", name: "Podcast" },
            { id: "video", name: "Video" },
          ]}
        />
        <ReferenceArrayInput
          label="name"
          source="bucket_ids"
          reference="content_buckets"
        >
          <SelectInput optionText="name" />
        </ReferenceArrayInput>
        <FileInput fullWidth source="file">
          <FileField target="_blank" source="src" title="title" />
        </FileInput>
        <TextInput fullWidth source="text" rows={20} multiline />
        <ReferenceArrayInput source="tag_ids" reference="content_tags">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export const ContentItemsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="type" />
      <DateField source="created_at" />
      {/* <ReferenceField
          reference="content_items_buckets"
          source="content_items_id"
          >
          <TextField source="content_buckets_id" />
          </ReferenceField> */}
    </Datagrid>
  </List>
);

export const ContentItemsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="type" />
      <FileField target="_blank" source="src" title="title" />
      <TextField source="text" rows={20} />
      <DateField source="created_at" />
      <ReferenceManyField
        reference="content_items_tags"
        target="content_items_id"
        sortBy="content_items_tags.content_tags_id"
      >
        <Datagrid>
          <TextField source="content_tags_id" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const ContentItemsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput fullWidth source="title" />
      <SelectInput
        fullWidth
        source="type"
        choices={[
          { id: "blog", name: "Blog" },
          { id: "podcast", name: "Podcast" },
          { id: "video", name: "Video" },
        ]}
      />
      <ReferenceArrayInput
        label="name"
        source="bucket_ids"
        reference="content_buckets"
      >
        <SelectInput optionText="name" />
      </ReferenceArrayInput>
      <FileInput fullWidth source="file">
        <FileField target="_blank" source="src" title="title" />
      </FileInput>
      <TextInput fullWidth source="text" rows={20} multiline />
      <ReferenceArrayInput source="tag_ids" reference="content_tags">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
