import { ReferenceField, ReferenceFieldProps } from "react-admin";

import FullNameField from "./FullNameField";

const ClientReferenceField = (
  props: Omit<ReferenceFieldProps, "reference" | "children" | "source"> & {
    source?: string;
  },
) => (
  <ReferenceField source="user_id" reference="clients_view" {...props}>
    <FullNameField />
  </ReferenceField>
);

ClientReferenceField.defaultProps = {
  source: "user_id",
};

export default ClientReferenceField;
