import { Card } from "@mui/material";
import {
  useTranslate,
  Title,
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  Edit,
  required,
} from "react-admin";

export function ContentBucketsCreate() {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput fullWidth source="name" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
}

export const ContentBucketsList = () => {
  const translate = useTranslate();

  return (
    <Card sx={{ mt: 2 }}>
      <Title title={translate("resources.segments.name", { smart_count: 2 })} />
      <List exporter={false}>
        <Datagrid rowClick="edit">
          <TextField source="name" />
        </Datagrid>
      </List>
    </Card>
  );
};

export const ContentBucketsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export const ContentBucketsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput fullWidth source="name" />
    </SimpleForm>
  </Edit>
);
