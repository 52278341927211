import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";
import VideoFile from "@mui/icons-material/VideoFile";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import matches from "../matches";
import clients from "../clients";
import SubMenu from "./SubMenu";

type MenuName = "menuCatalog" | "menuSales" | "menuCustomers";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        name="pos.menu.content"
        icon={<clients.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/content_items"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.content.name`, {
            smart_count: 2,
          })}
          leftIcon={<VideoFile />}
          dense={dense}
        />
        <MenuItemLink
          to="/content_tags"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.tags.name`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/content_buckets"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.buckets.name`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        name="pos.menu.clients"
        icon={<clients.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/matches"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.matches.name`, {
            smart_count: 2,
          })}
          leftIcon={<matches.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/matchmaker_clients_view"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.clients.name`, {
            smart_count: 2,
          })}
          leftIcon={<clients.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
