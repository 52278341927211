import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  Admin,
  Resource,
  localStorageStore,
  useStore,
  StoreContextProvider,
} from "react-admin";

import { authProvider } from "./authProvider";
import { Dashboard } from "./dashboard";
import englishMessages from "./i18n/en";
import { Layout, Login } from "./layout";
import clients from "./clients";
import matches from "./matches";
import { themes, ThemeName } from "./themes/themes";
import { dataProvider } from "./dataProvider/dataProvider";
import {
  ContentTagsEdit,
  ContentTagsList,
  ContentTagsShow,
  ContentTagsCreate,
} from "./tags/Tags";
import {
  ContentItemCreate,
  ContentItemsEdit,
  ContentItemsList,
  ContentItemsShow,
} from "./content/ContentItems";
import {
  ContentBucketsCreate,
  ContentBucketsEdit,
  ContentBucketsList,
  ContentBucketsShow,
} from "./buckets/Buckets";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === "fr") {
      return import("./i18n/fr").then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
  },
  "en",
  [
    { locale: "en", name: "English" },
    { locale: "fr", name: "Français" },
  ],
);

const store = localStorageStore(undefined, "ECommerce");

const App = () => {
  const [themeName] = useStore<ThemeName>("themeName", "soft");
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      store={store}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme="light"
    >
      <Resource
        options={{ label: "Tags" }}
        name="content_tags"
        list={ContentTagsList}
        show={ContentTagsShow}
        create={ContentTagsCreate}
        edit={ContentTagsEdit}
      />
      <Resource
        options={{ label: "Buckets" }}
        name="content_buckets"
        list={ContentBucketsList}
        show={ContentBucketsShow}
        create={ContentBucketsCreate}
        edit={ContentBucketsEdit}
      />
      <Resource name="matchmaker_clients_view" {...clients} />
      <Resource name="matches" {...matches} />
      <Resource
        options={{ label: "Content Items" }}
        name="content_items"
        list={ContentItemsList}
        show={ContentItemsShow}
        create={ContentItemCreate}
        edit={ContentItemsEdit}
      />
    </Admin>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
);

export default AppWrapper;
